@use "./variables" as *;
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");

.navbar .nav-link {
    color: #533810 !important;
    font: 500 15px Noto Sans, sans-serif;
}

.navbar .nav-link.active {
    color: #ff8744 !important;
}

/* Donate Now Button */
.btn-primary {
    background-color: #ff8744 !important;
    color: #ffffff !important;
    font: 600 15px Noto Sans, sans-serif;
    border-color: #ff8744 !important;
    height: 40px;
    display: flex;
    align-items: center;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    border-bottom: 5px solid $primary;
    border-radius: 5px;
    color: #533810;
    opacity: 1;
    font-weight: bold;
    height: 30px;
    padding: 2px 2px 5px 2px;
}

.ant-tabs .ant-tabs-tab-btn {
    transform: none;
    transition: none;
    color: #533810;
    height: 30px;
    padding: 2px 2px 5px 2px;
}

.ant-tabs-ink-bar {
    display: none;
    height: 30px;
}

.ant-tabs-nav-wrap {
    height: 30px;
}

/* Back Button */
.back-button {
    margin: 20px 0;
}

.back-button-link {
    color: #533810 !important;
    font: 600 15px Noto Sans, sans-serif;
    cursor: pointer;
}

/* Two Column Layout */
.image-column {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

.text-column {
    display: flex;
    flex-direction: column;
}

.title {
    color: #533810 !important;
    font: 600 15px Noto Sans, sans-serif;
    margin-bottom: 15px;
    font-size: 30px;
    font-weight: bold;
}

.gold {
    font-size: 4vw;
    font-family: "Great Vibes", cursive;
    font-weight: 500;
    //text-transform: uppercase;
    line-height: 1;
    background: linear-gradient(90deg,
            rgba(186, 148, 62, 1) 0%,
            rgba(236, 172, 32, 1) 20%,
            rgba(186, 148, 62, 1) 39%,
            rgba(249, 244, 180, 1) 50%,
            rgba(186, 148, 62, 1) 60%,
            rgba(236, 172, 32, 1) 80%,
            rgba(186, 148, 62, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s infinite;
    background-size: 200%;
    background-position: left;
    letter-spacing: 2px;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

@keyframes shine {
    to {
        background-position: right;
    }
}

.description {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
}

/* Adjustments for mobile view */
.offcanvas {
    background-color: #fff;
    /* Optional: Sidebar background */
}

.offcanvas-header img {
    width: 150px;
    height: auto;
}

/* Optional: Style nav links inside sidebar */
.offcanvas-body .nav-link {
    color: $font-color !important;
    font: 600 18px Noto Sans, sans-serif;
}

.offcanvas-body .nav-link:hover {
    color: $font-color;
}

.menu-item {
    display: block;
    padding: 10px 15px;
    border-radius: 4px;
    color: $font-color;
    text-decoration: none;
    transition: background-color 0.3s ease, color 0.3s ease;

    &:hover {
        background-color: lighten($primary, 10%);
        color: $primary;
    }
}

.menu-item.active {
    background-color: $primary;
    color: $white;
    font-weight: bold;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.loginwrapper,
.setpasswordwrapper {
    .errorMassage {
        font: $font-weight-bold 14px/20px $font-family;
    }

    .defaultFontColor {
        color: $font-color;
    }

    .an_account {
        font: $font-weight-normal 16px/25px $font-family;
    }

    .fw-bold {
        font-weight: 800 !important;
        font-size: 35px;
        font-family: $font-family;
        text-align: center;
    }

    .signInEnterUserName {
        display: flex;
        justify-content: center;
        font: 600 25px Noto Sans, sans-serif;
    }

    .forgetPassword {
        padding: 1rem 0rem;
        text-align: end;
        margin-bottom: 20px;

        >span {
            font: $font-weight-bold 16px/20px $font-family;
        }
    }

    .signInIcons {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        cursor: pointer;
    }

    .signInputField {
        color: $font-color;
        font: $font-weight-bold 16px/33px $font-family;

        &::-webkit-input-placeholder {
            opacity: 0.3;
            font: $font-weight-bold 16px/33px $font-family;
            color: $font-color !important;
        }
    }

    .text-end {
        font: $font-weight-bold 18px/80px $font-family;
    }

    .px-5 {
        font: $font-weight-bold $font-size-large/$font-size-large $font-family;
    }

    .signUp {
        font: $font-weight-bold 18px/25px $font-family;
    }

    .brand-text {
        color: $font-color;
        font: $font-weight-bold 30px/44px $font-family;
    }

    .brand-logo {
        width: fit-content;
    }

    .loginBackground {
        background: $secondary-color;
    }

    .templeName {
        font: $font-weight-bold 20px/43px $font-family;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.loginwrapper {
    .errorMassage {
        font: $font-weight-bold 14px $font-family;
    }

    .defaultFontColor {
        color: $font-color;
    }

    .an_account {
        font: $font-weight-normal 16px $font-family;
    }

    .fw-bold {
        font-weight: 800 !important;
        font-size: 35px;
        font-family: $font-family;
    }

    .signInEnterUserName {
        display: flex;
        justify-content: center;
        font: 600 25px Noto Sans, sans-serif;
        margin-bottom: 35px;
    }

    .signInEnterMobile {
        display: flex;
        justify-content: center;
        font: 600 16px Noto Sans, sans-serif;
        color: $primary;
    }

    .forgetPassword {
        padding: 1rem 0rem;
        text-align: end;
        margin-bottom: 20px;
    }

    .forgetPassword>span {
        font: $font-weight-bold 16px/20px $font-family;
    }

    .signInIcons {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        cursor: pointer;
    }

    .signInputField {
        color: $font-color;
        font: $font-weight-bold 16px/33px $font-family;

        &::-webkit-input-placeholder {
            opacity: 0.3;
            font: $font-weight-bold 16px/33px $font-family;
            color: $font-color !important;
        }
    }

    .text-end {
        font: $font-weight-bold 18px/80px $font-family;
    }

    .px-5 {
        font: $font-weight-bold $font-size-large $font-family;
    }

    .signUp {
        font: $font-weight-bold 18px/25px $font-family;
    }

    .brand-text {
        color: $font-color;
        font: $font-weight-bold 30px/44px $font-family;
    }

    .brand-logo {
        width: fit-content;
    }

    .templeName {
        font: 600 20px/43px $font-family;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .loginBackground {
        background: $secondary-color;
    }
}

// @import "../bootstrap-extended/include"; // Bootstrap includes
// @import "../components/include"; // Components includes

$auth-1-inner-max-width: 400px !default;

.auth-wrapper {
    display: flex;
    flex-basis: 100%;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    width: 100%;

    .auth-inner {
        width: 100%;
    }

    &.auth-basic {
        align-items: center;
        justify-content: center;
        overflow: hidden; // For v1 scroll for long auth form

        .auth-inner {
            position: relative;

            &:before {
                width: 244px;
                height: 243px;
                content: " ";
                position: absolute;
                top: -54px;
                left: -46px;
                background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAPQAAADzCAMAAACG9Mt0AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAA9KADAAQAAAABAAAA8wAAAADhQHfUAAAAyVBMVEUAAAD///+AgP+AgP9mZv+AgNWAgP9tbf9gYP+AgP9xcf9mZv+AZuaAgP9dXf90dOhiYv92dv9mZu5mZv93d+53d/9paf94afCAcfFrXvJra/9mZvJzZvJzc/JoaP96b/Rqav91aupsYvV2bOt2bPVxaPZ7cfZqavZyau1waPd4aO9xafBxafh4afB1bfh4avFuZ/F2afJzZvJzZ/N0aPN0bvN3bPR0ae5yZ/R3be93bfR1au9zafBxbPVzavV0a/F0a/ZyafFwaPKZm3nTAAAAQ3RSTlMAAQIEBQYGBwgICQoKCgsLDQ0PDw8PERESExMUFBQWFxgYGhoaGxsdHSAgIiIiIyQlJygqLCwtLi8vLzAzNDU3Nzg7h9vbHgAAA9RJREFUeNrt3ftS2kAUx/Fc1gSyWsErtuJdRDQiiteolb7/QzUoTm07k4AzObuu3/MCez45yWbzT36eZ6b8erO1e1B97baadd+zocJWmg0HaXe/+uqmg2GWtkLT5Lle1m9LdhG2+1lvzuiUO1knEF81yFc1N+35m15kZOGodz1vyLx+v2Lseq/erxtZd/NuweCTtfiwaWLOD5FnsqI7+VnP3y8afnEs3Es/1+H1qvETwuq18B7e6VlwLup1ZM8kWWQBOsrmHL7GVtxvYRZYgQ4ywae61ffsqH5Lbq20bQm6ncp9P2ehJegwE/u+rl95ttSwLrVSc2ANetAU28dSa9Cp2E623bUG3d2VWmn/wBq0XCugQYMGLdVKoOJaoiuok1NdXSW1WAUfRPtRUllflaJf5ZE/O9pXVbZUPTov5c+IDqvtRwStdTgLutoxy6GnGfYb2o+1I2gd+1OiqzfLocvVE7TSDqG1mgodaqfQZbvZC9rXjqG1X45WzqFVKVpk0LLo4lGP0ZGD6KgMnTiITkrQgXYQrYNitHISrYrRsZPouBhdcxJdK0YnTqKTYrR2Eq1BgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRoh9DH59ag86ACoSYOL61B55EUQk1s3VqDzsNHhJpYe7QGncfMSHUxaliCHgcKSXVxeWQJehwdJdXF4dAS9DgkTKqLxuibFeiXODixNi7OrEC/BP+JtbE0WrYA/RrxKNfH2YUF6NegSbk+Gk87xtErN6EsWm88fzeMXpwE9EruLns/l42io4dJFLPo2/Po1w+D6IW7t9Bt2SPx3vOOMfS7eHVZtN54ulg2go56138Ct4XRunE2Ovsmjg46WeddUoUWr6WL0fCoIYgO2/2s91fstDZQjcPL0ePt5flpdXUwqW46uMrS1j95JNpQrW0dHp9UV/uT2m416/8HVGg3qzhpBjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KBBgwYNGjRo0KC/FDpx0pwUo2tOomvF6NhJdFyMVk6iVTE6cBIdeF9vJyvZx/I/AzuIjsrQvoNovwzt4FamSs0Ojrp80PmvoB0zh940pb7azf1yg7t0LIt978uppzbnalfucDW92ZndLPRmKweGPduYJ+zoM5/Dk+gD5NdvLhXXPp88qcUqmEH5G5JZRs6cuxwIAAAAAElFTkSuQmCC");
                // @include media-breakpoint-down(sm) {
                //   display: none;
                // }
            }

            &:after {
                width: 272px;
                height: 272px;
                content: " ";
                position: absolute;
                bottom: -55px;
                right: -75px;
                background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARAAAAEQCAMAAABP1NsnAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAABEKADAAQAAAABAAABEAAAAAAQWxS2AAAAwFBMVEUAAAD///+AgICAgP9VVaqqVf+qqv+AgL+AgP9mZsxmZv+ZZv+AgNWAgP9tbdttbf+Sbf+AYN+AgN+AgP9xceNmZv+AZuaAZv90dOh0dP9qav+AauqAav+AgP92dv9tbf+Abe2Abf93Zu53d+6AcO94afCAcfF5a+R5a/JzZuaAZvKAc/J5bed5bfOAaPN6b/R1auqAavR6ZvV6cPV2bOuAbPV7aPZ2be2AbfZ7au17avZ3Zu53b+57a+97a/d4aO9J6CoeAAAAQHRSTlMAAQICAwMDBAQFBQUGBgcHBwgICAkKCgoLCwwMDAwNDg4ODw8QERITExQUFBUVFhcYGBkZGhobHBwdHR4eHx8gJ5uMWwAAA/FJREFUeNrt2G1XEkEYxvHZNk2xHGzdbKFl0cTwgdSkCKzu7/+t4pw6sAjtjIueE/f8r3fMO35nZnbuy5gVGcvfzJe0rnTfGI+MggGJRUZnbpPIhJKt88nU53JnFULvyISY6KAv8vPj0vr2rYwiE2Z2B9J+uNYcyyQxwWZvaeGH3G4bMjsvI/kcwTC/V+7kLoahlITzQojP3ZFgsJCh7IJQzpX0QFj4uMiY18eDMZ9bZCF9OQahnK6cm/Y7js0sh/LF3Auv1PlQd3MxbdXYIQspV44EEEAAAWTNDAYYkKdJbNMsLzYueZbaZ2iM46RVbHBaiZ9Js+nHEdli42N9XuSen5hGp1CQTuOJQDRsD99N4gMSpYWapNH6IJo83CIeILZQFesEaber79NCWRoukOpNEnW0gXQqD81w6ACxhbrYde7VuFCYeA2QRCNIsgZISyNIqz6IyhPjOjNVIFYniK3dmKU6QdLaJUimEySrDZLrBMlrgxRKU7sxCw/EMe0CAggggADySJCqxixIkKpNEh6IozELD8RxjQACCCCAAPJIkKrGLEgQXqqAAEJjxrQLCCCAAEJjRmNGY8a0CwgggABCYwYIfQgggNCYMe0CAggggNCY0ZjRmDHtAgIIIIAAQmNGHwIIIDRmTLuAAAIIIDRmNGY0Zky7gAACCCCA0JjRhwACCI0Z0y4ggAACCI0ZjRmNGdMuIIAAAgggNGb0IYAAQmPGtAsIIIAAQmNGY0ZjxrQLCCCAAAIIjRl9CCCA0Jgx7QICCCCA0JjRmNGYMe0CAggggABCY0YfAgggNGZMu4AAAgggNGY0ZjRmTLuAAAIIIIDQmNGHAAIIjRnTLiCAAAIIjRmNGY0ZIEy7gAACCCA0ZvQhgABCY8a0CwgggABCY0ZjBgiNGdMuIIAAAgiN2f/Sh+Q6PfLaIJlOkKw2SKoTJK3dmFmdILb2tBvrBIlrg5iWRo+WqQ+SaARJ1gCJAzsxThCN16p1vNurGjNjoo42j07kAHFskoY2kEbl33U0ZgoPjXW+Rl0gkarnahqtDaJKxMPDDWIiNafGenh4gExvVhXfmk7Da6L1AVGxSby2h6MxK79Zk42ea1pJbJ48sU2zDezQ8iy1z6BBwoyjMQsvXp8YQAAhgADilRfyy+wf8WqZZUfGZihvgZiB3FybC+kCUU5XLkAo50C+gbBQdUzkAIVyejIAYfFTI1solHP2HgNCnHn5AYNy4jvpoVB6fVzL91cwzLJ9Lfd7S0jhehxO5H5/yePr1W6gHonI7fJ5ORSR/n6Q2yQanq763zuXU5LJZRKiyD/W9/pjkdPZz0/yJ8fqVyry+qQZDMjJKoDfy8bRVhHhQTwAAAAASUVORK5CYII=");
                z-index: -1;
                // @include media-breakpoint-down(sm) {
                //   display: none;
                // }
                // @include media-breakpoint-down(sm) {
                //   display: none;
                // }
            }
        }
    }

    &.auth-cover {
        align-items: flex-start;

        .auth-inner {
            height: 100vh;
            overflow-y: auto; // For v2 scroll for long auth form
            height: calc(var(--vh, 1vh) * 100);
        }

        .brand-logo {
            position: absolute;
            top: 2rem;
            left: 2rem;
            margin: 0;
            z-index: 1;
            justify-content: unset;
        }
    }

    &.auth-basic .auth-inner {
        max-width: $auth-1-inner-max-width;
    }

    .brand-logo {
        display: flex;
        justify-content: center;
        margin: 1rem 0 2rem 0;

        .brand-text {
            font-weight: 600;
        }
    }

    .auth-footer-btn {
        .btn {
            padding: 0.6rem !important;

            &:not(:last-child) {
                margin-right: 1rem;
            }

            &:focus {
                box-shadow: none;
            }
        }
    }

    // two steps verification
    .auth-input {
        max-width: 50px;
        padding-right: 0.571rem;
        padding-left: 0.571rem;
    }

    // multi-steps register
    .custom-options-checkable {
        .plan-price {
            .pricing-value {
                font-size: 3rem;
            }

            sup {
                top: -1.5rem;
                left: 0.2rem;
            }

            sub {
                bottom: 0;
                right: 0.14rem;
            }
        }
    }
}

@media (min-width: 1200px) {
    .auth-wrapper {
        &.auth-cover .auth-card {
            width: $auth-1-inner-max-width;
        }
    }
}

@media (max-width: 575.98px) {
    .auth-wrapper.auth-cover .brand-logo {
        left: 1.5rem;
        padding-left: 0;
    }
}

.auth-wrapper .auth-bg {
    background-color: $white;
}

.dark-layout {
    .auth-wrapper .auth-bg {
        // background-color: $theme-dark-card-bg;
    }
}

// Auth pages for mobile and small height & width screen
@media (max-height: 825px) and (max-width: 991.98px) {
    .dark-layout {
        .auth-wrapper .auth-inner {
            // background-color: $theme-dark-card-bg;
        }
    }

    .auth-wrapper {
        .auth-bg {
            padding-top: 3rem;
            margin: auto 0;
        }

        .auth-inner {
            background-color: $white;
        }

        &.auth-cover {
            .auth-inner {
                padding-bottom: 1rem;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
            }

            .brand-logo {
                position: relative;
                left: 0;
                padding-left: 1.5rem;
            }
        }
    }
}

.phonewrapper {
    label {
        /* margin-bottom: 0px; */
        font: bold 15px Noto Sans;
    }

    input::placeholder {
        color: $font-color !important;
        opacity: 60% !important;
        font: normal normal bold 13px/20px Noto Sans !important;
    }

    .react-tel-input {
        color: $font-color !important;
        border: none !important;
        background-color: $secondary-color !important;
        font: normal normal normal 13px/20px Noto Sans;
        border-radius: 5px;
        height: 45px;
        display: flex;
        align-items: center;
    }

    .react-tel-input .form-control {
        width: inherit;
        height: 45px;
    }

    input {
        color: $font-color !important;
        border: none !important;
        background-color: $white !important;
        font: normal normal normal 13px/20px Noto Sans;
        border-radius: 5px;
    }

    .react-tel-input .flag-dropdown {
        background-color: $white !important;
        border: none !important;
        border-right: 1px solid hsl(0, 0%, 80%) !important;
        margin-right: 5px;
    }

    .react-tel-input .selected-flag:hover {
        background-color: $secondary-color !important;
    }

    .react-tel-input .flag-dropdown.open .selected-flag {
        background-color: $secondary-color !important;
    }

    .country-list {
        background-color: $secondary-color !important;

        ::-webkit-scrollbar {
            width: 5px;
        }

        ::-webkit-scrollbar-thumb {
            background-color: $primary-color !important;
            border-radius: 5px;
            height: 30px;
            width: 10px !important;
        }
    }

    .react-tel-input .country-list .country:hover {
        background-color: $primary-color !important;
        color: $white;
    }

    .react-tel-input .country-list .country:hover .dial-code {
        color: $white !important;
    }

    .dial-code {
        color: $font-color !important;
    }

    .react-tel-input .country-list .country.highlight {
        background-color: $primary-color !important;
    }

    .react-tel-input .country-list .country.highlight>span {
        font: normal normal bold 13px/20px Noto Sans;
        color: $white !important;
    }

    .react-tel-input .country-list .search {
        padding: 0.5rem 1.5rem 0.5rem 0rem;
    }

    .react-tel-input .country-list .search-box {
        width: 100%;
    }
}

.addAction-btn {
    padding: 8px 20px;
    //margin-left: 10px;
    width: 170px;
    height: 45px;
    font: $font-weight-bold $font-size-large $font-family;
    border-color: $primary-color !important;
    background-color: $primary-color !important;
}

.signUpText {
    color: $primary;
    cursor: pointer;
}

.otpBtn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.otherOption {
    font-size: 14px;
}

.ant-otp .ant-otp-input {
    height: 40px !important;
    color: $font-color;
    font-weight: 600;
}

.inputBox {
    height: 45px;
}

label {
    font-weight: 500;
}

// Antd Date Picker css
.ant-picker {
    /* margin-top: 32px !important; */
    display: flex !important;
    height: 45px !important;
    background: $white !important;
}

.ant-picker-outlined {
    background: $secondary-color;
    border: none !important;
    //border: 1px solid $primary-color !important;
    color: $primary-color !important;
    font: bold 13px "Noto Sans", sans-serif;
}

.ant-picker .ant-picker-input>input::placeholder {
    font-weight: 600 !important;
    color: #533810 !important;
}

.ant-picker .ant-picker-input>input {
    font-weight: 700 !important;
    color: $font-color !important;
}

.ant-picker-suffix {
    color: #533810 !important;
}

/* .ant-pagination {
  display: none !important;
} */
.ant-picker-header-view {
    color: $font-color !important;
}

.ant-picker-date-panel .ant-picker-content th {
    color: $font-color !important;
    font-weight: 500 !important;
}

.ant-picker-dropdown .ant-picker-content td {
    color: $font-color !important;
}

.ant-picker-now-btn {
    color: $font-color !important;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
    background-color: $primary-color;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid $font-color;
}

.ant-picker-suffix {
    display: none !important;
}

.loader {
    text-align: center;
    margin-top: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#serviceCard {
    .ant-card-cover {
        height: 200px;
    }
}

.cardContainer {
    width: 100%;
    /* Allow the card to take the full width of its parent column */
    max-width: 300px;
    height: 100%;
    /* Ensures full height within the container */
    max-height: 400px;
    margin: 10px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.cardContainer:hover {
    transform: translateY(-5px);
}

.cardImage {
    height: 100px;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.serviceCardImage {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.cardImage1 {
    height: 200px;
    width: 200px;
    max-width: 70%;
    height: auto;
    object-fit: cover;
}

@media (max-width: 576px) {

    /* xs breakpoint */
    .cardColumn {
        display: flex;
        justify-content: center;
        /* Center the card in mobile view */
    }
}

.cardTitle {
    color: $font-color;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // max-width: calc(100% - 100px);
}

.cardTitle1 {
    color: $font-color;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

.cardDescription {
    flex: 1;
    /* Ensures the description takes available space */
    font-size: 14px;
    color: $font-color;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.cardReadMore {
    color: $primary;
    cursor: pointer;
    text-decoration: underline;
}

.cardDate {
    margin-top: 10px;
    color: gray;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.cardAmount {
    margin-top: 10px;
    color: $font-color;
    font-size: 14px;
    display: flex;
    font-weight: 500;
    align-items: center;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .cardContainer {
        max-width: 250px;
        /* Smaller max-width for medium screens */
    }
}

@media (max-width: 992px) {
    .cardContainer {
        max-width: 100%;
        /* Cards take full width on small screens */
    }
}

@media (max-width: 576px) {
    .cardContainer {
        max-width: 100%;
        /* Ensure full width on mobile screens */
    }
}

.pagination-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    z-index: 10;
    text-align: center;
    display: flex;
    justify-content: center;
}

.userCardContainer {
    .ant-card-body {
        background-color: $white;
        border-radius: 14px;
    }

    .ant-card-meta-title {
        color: $font-color;
        font: 700 20px Noto Sans, sans-serif;
    }
}

.ant-select-selector {
    min-height: 38px;
    color: $font-color;
    font: 500 15px Noto Sans, sans-serif;
}

.antInput {
    color: $font-color;
    font: 500 15px Noto Sans, sans-serif;
    min-height: 38px;
}

.antInput:hover {
    border-color: $primary !important;
    /* Red border on hover */
    outline: none;
    /* Remove outline on focus */
}

.antInput:focus {
    border: none;
    /* Remove the border */
    outline: none !important;
    /* Remove the focus outline */
}

.ant-select-selection-overflow {
    display: flex;
    // justify-content: center;
    align-items: center;
}

.ant-select-selection-wrap {
    align-self: normal !important;
}

#sevaBooking {
    .ant-select-selection-item {
        color: black !important;
    }

}

.desktopView {
    height: 100%;
    margin-bottom: 10px;
}

.sevaBookingImage {
    width: 70%;
    border-radius: 8px;
}

@media (max-width: 768px) {
    .desktopView {
        display: none;
    }
}

.mobileView {
    display: none;
}

@media (max-width: 768px) {
    .mobileView {
        display: block;

        .form-container {
            // margin-top: 20px;
        }

        .mobile-field {
            margin-bottom: 20px;
        }
    }
}

.form-label {
    font: bold 15px Noto Sans;
    color: $font-color;
}

.form-control:hover {
    border-color: $primary !important;
    /* Red border on hover */
    outline: none;
    /* Remove outline on focus */
}

.form-control:focus {
    border: none;
    /* Remove the border */
    outline: none !important;
    /* Remove the focus outline */
}

.booking-title {
    color: $font-color;
}

.ant-select-selector:hover {
    border-color: $primary !important;
    /* Red border on hover */
    outline: none;
}

.ant-select-selector:focus {
    border: none;
    /* Remove the border */
    outline: none !important;
    /* Remove the focus outline */
}

.commonListTable {
    #addBtn {
        background-color: $primary-color !important;
        border: none;
    }

    #cancel {
        background-color: $primary-color !important;
        border: none;
    }

    #submit {
        background-color: $green !important;
        border: none;
    }

    .btn-close {
        display: none;
    }

    .modal-title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .cancleIcon {
        cursor: pointer;
    }

    .swal2-actions {
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .swal2-confirm {
        margin-right: 10px;
    }

    .swal2-cancel {
        margin-right: 10px;
    }

    .ant-table {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 10px;
    }

    .ant-table-container {}

    .ant-table-body {
        scrollbar-width: none;
    }

    .ant-table-tbody>tr>td {
        padding: 0 16px !important;
    }

    .ant-table-thead>tr>th {
        padding: 10px 16px !important;
    }

    .ant-table-header {
        top: 50px !important;
    }

    .ant-table-row .ant-table-cell {
        color: $font-color !important;
        height: 35px;
        font-weight: 400;
    }

    .ant-table-thead .ant-table-cell {
        height: 35px;
        font-weight: 500;
        color: $font-color !important;
        background-color: $white;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    }

    .ant-table-row:nth-child(even) .ant-table-cell {
        background-color: $secondary-color;
    }
}

#bookingDetailCard {
    .ant-card-body {
        padding: 0px;
    }
}

.detailRow {
    color: $font-color !important;
    font: 500 14px Noto Sans, sans-serif !important;
    padding: 5px 24px !important;
}

.ant-descriptions-view {
    th {
        background-color: $secondary-color !important;
        // Default width for larger screens
        width: 300px;

        // Adjust width for medium screens
        @media (max-width: 1200px) {
            width: 250px;
        }

        // Adjust width for smaller screens
        @media (max-width: 768px) {
            width: 200px;
        }

        // Adjust width for very small screens
        @media (max-width: 480px) {
            width: 150px;
        }
    }
}

.detailTitle {
    color: $font-color !important;
    font: 500 25px Noto Sans, sans-serif !important;
}

.commonTextColor {
    color: $font-color !important;
    font-family: Noto Sans, sans-serif !important;
}

.amountColor {
    color: $primary;
}

.bookingIdColor {
    color: $gold;
}

.commonFont {
    font: 500 Noto Sans, sans-serif !important;
}

.commonFontSizeColor {
    color: $font-color;
    font: 700 16px Noto Sans, sans-serif !important;

}

.commonCardShadow {
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-control::placeholder {
    color: $font-color !important;
    font-family: Noto Sans, sans-serif !important;
}

.ant-skeleton-image {
    width: 100% !important;
}

.stateDrop {
    .ant-select-selection-item {
        background-color: $white !important;
        color: $font-color !important;
    }
}

.userProfileField {

    .ant-picker,
    .react-tel-input,
    .form-control {
        height: 38px !important;
        border: 1px solid hsl(0, 0%, 80%) !important;
    }
}

/* Gallery Container */
.gallery-container {
    padding: 20px;
    margin-bottom: 70px;
}

/* Image Card */
.image-card {
    width: 100%;
    height: 200px;
    /* Fixed height for uniform cards */
    display: flex;
    align-items: center;
    justify-content: center;
    //background-color: #f9f9f9;
    border-radius: 8px;
    //box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

/* Pagination Card */
.pagination-card {
    margin-top: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.pagination-card {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0px;
    z-index: 10;
}

/* Spinner */
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
}

/* No Images */
.no-images {
    text-align: center;
    font-size: 1.5rem;
    color: #777;
}

/* Mobile Responsiveness */
@media screen and (max-width: 576px) {
    .image-card {
        height: 150px;
        /* Adjust height for smaller screens */
    }

    .pagination-card {
        margin-top: 16px;
    }
}

.newsCarouselContainer {
    position: relative;
    // padding: 10px;
}

/* Carousel Navigation Buttons */
.carouselButton {
    position: absolute;
    top: 50%;
    z-index: 10;
    transform: translateY(-50%);
    background-color: $primary;
    border-radius: 50%;
    color: white;
}

.carouselLeft {
    left: -30px;
}

.carouselRight {
    right: -30px;
}

/* Adjust buttons for mobile screens */
@media (max-width: 768px) {
    .carouselLeft {
        left: 5px;
    }

    .carouselRight {
        right: 5px;
    }
}

.punyarJakSkeleton {
    .ant-skeleton {
        width: 100% !important;
    }
}

.recommendedLabel {
    position: absolute;
    top: -40px;
    left: -1px;
    right: 0;
    background-color: $primary;
    color: white;
    text-align: center;
    padding: 10px 0;
    border-radius: 10px 10px 0 0;
    font: 600 15px Noto Sans, sans-serif;
    font-weight: bold;
    z-index: 1;
    width: calc(100% + 2.5px);
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.3);

    // margin: 0;
}

@media (max-width: 576px) {
    .recommendedLabel {
        top: -40px;
        left: -2px;
        width: calc(100% + 3.5px);
        box-shadow: 0 4px 5px rgba(0, 0, 0, 0.3);
    }

    #scannerCard {
        margin-top: 40px;
    }

    .margin {
        margin-top: 40px;
    }
}

#scannerCard {
    border-radius: 0 0 10px 10px;
    height: 100%;
    border: 2px solid $primary;
    position: relative;
}

.commonDonationCard {
    .ant-card-head {
        min-height: 0px !important;
        border-bottom: none !important;
        margin: 0 !important;
        text-align: center;
        font: 600 17px Noto Sans, sans-serif;
        color: $font-color;
    }

    border: 2px solid $primary !important;

    .ant-typography {
        font: 600 15px Noto Sans, sans-serif;
        color: $font-color;
    }

    p {
        font: 500 15px Noto Sans, sans-serif;
        color: black;
    }
}

.infoText {
    font: 500 15px Noto Sans, sans-serif;
    color: $font-color;
}

.privacy_policy {
    p {
        font: 500 15px Noto Sans, sans-serif;
        color: $font-color;
    }

    h3 {
        color: $font-color;
    }

    li {

        color: $font-color;
    }
}

.custom-datepicker {
    z-index: 1000;
    position: relative;
    border: 1px solid #707070;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 14px;
    color: #707070;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.29);
    transition: box-shadow 0.3s ease;
    margin-bottom: 10px;
}

.label {
    color: $font-color !important;
    font-size: 16px;
    font-weight: 600;
    font-family: Noto Sans, sans-serif !important;
}

.submitButton {
    background-color: $primary;
}

.antdDatePickerComponent {
    height: 38px !important;
    border: 1px solid rgb(216, 216, 216) !important;
}

.antdDatePickerComponent:hover {
    border-color: $primary !important;
    /* Red border on hover */
    outline: none;
    /* Remove outline on focus */
}

.antdDatePickerComponent:focus {
    border: none;
    /* Remove the border */
    outline: none !important;
    /* Remove the focus outline */
}

.addBtnRoom {
    color: $font-color;
    font-family: Noto Sans, sans-serif !important;
}

.card-header {
    position: absolute;
    top: 10px;
    left: 10px;
}

.commonWordWrap {
    max-width: 100%; // Takes full width available
    word-wrap: break-word; // Allows breaking into the next line
    overflow-wrap: break-word; // Ensures long words wrap properly
    display: inline-block
}

@media (max-width: 768px) { /* Adjust breakpoint as needed */
    #sevaListTab .ant-tabs-nav {
        position: sticky !important;
        top: -10px !important;
        z-index: 1000;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
        background-color: white !important;
        width: 100%;
        padding: 0px;
    }
}
